.jobDates {
    display: grid;
    grid-template-columns:  auto 1fr auto 1fr;
    grid-template-rows: repeat(2, auto);
    column-gap: 10px;
    grid-template-areas: "label1 date1 label2 date2" ". comment comment comment";
    align-items: baseline;

    .label1{
        grid-area: label1;
    }

    .date1{
        grid-area:  date1;
    }

    .label2{
        grid-area: label2;
    }

    .date2{
        grid-area:  date2;
    }

    .comment {
        grid-area: comment;
    }
}