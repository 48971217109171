.card-header-toolbar {
  min-height: 56px;
}

.card-stretch {
  height: calc(100% - 10px);
}

.card-body {
  :last-child {
      margin-bottom: 0;
  }
}

.bin-access-time {
  min-width: 80px;
  flex-grow: 1;
}

.map > div {
  position: static!important;
  min-height: 200px;
}

.icon {
  height: 40px;
  width: 40px;
}

.card-title {
  margin-left: 15px;
}
