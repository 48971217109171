.request-service {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "data map";

  div {
    margin-bottom: 0 !important;
  }

  .data {
    grid-area: data;
    display: grid;
    grid-template-rows: repeat(8, auto);
    grid-template-areas: "firstName""lastName""email""phone""addressDetail""address""addressPanel""request";
    row-gap: 10px;
    margin-right: 10px;

    .firstName {
      grid-area: firstName;
    }

    .lastName {
      grid-area: lastName;
    }

    .email {
      grid-area: email;
    }

    .phone {
      grid-area: phone;
    }

    .addressDetail {
      grid-area: addressDetail;
    }

    .address {
      grid-area: address;
    }

    .addressPanel {
      grid-area: addressPanel;
    }

    .request {
      grid-area: request;
    }

    .data-row {
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-template-areas: "label data";

      label {
        grid-area: label;
      }

      .data-input {
        grid-area: data;
      }

      // div.mb-3 {
      //   grid-area: data;
      // }
    }

    @media (max-width: 1024px) {
      .data-row {
        grid-template-columns: none;
        grid-template-rows: repeat(2, auto);
        grid-template-areas: "label""data";

        label {
          grid-area: label;
        }

        .data-input {
          grid-area: data;
        }
      }
    }

  }

  .map {
    grid-area: map;
  }

  .map>div {
    position: static !important;
    min-height: 200px;
  }
}

@media (max-width: 480px) {
  .request-service {
    grid-template-columns: none;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: "data""map";

    .map>div {
      position: static !important;
      width: 100%;
    }
  }
}

.modal-width {
  min-width: 60%;
}
