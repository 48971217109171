.label-col {
   text-align: right;
   max-width: 40%;
   flex-basis: 40%;
}

.closed-dates-table{
   margin: 10px 20px;
   padding: 20px;
   border: 2px solid var(--branding-feather-grey);
   border-radius: 10px;
   font-size: 0.75rem;
}

@media screen and (min-width: 990px) {
   .label-col {
      max-width: 30%;
      flex-basis: 30%;
   }
}
@media screen and (min-width: 1920px) {
   .label-col {
      max-width: 41.5%;
      flex-basis: 41.5%;
   }
}