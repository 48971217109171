:root {
    --branding-sunburst-orange: #e54800;
    --branding-sea-green: hsl(183, 68%, 45%);
    --branding-sea-green-light: hsl(183, 68%, 90%);
    --branding-blue: #28a8de;
    --branding-feather-grey: #e6e5e5;
    --branding-dim-grey: #555;
    --branding-slate-grey: #31434f;
    --branding-dark-slate-grey: #27323a;

    --branding-dark: #2f444f;
    --branding-light: hsl(210, 0%, 95%);

    --branding-sunburst-orange-transparent: rgba(229, 72, 0, 0.851);
    --branding-sunburst-orange-login: rgba(229, 72, 0, 0.80);
    --branding-dark-slate-grey-login: rgba(48,68,79, 0.8);

    --branding-hover: rgba(229, 72, 0, 1);
    --hover-colour: #ddd;
    --branding-error: #ff3333;

    --toastify-icon-color-success: hsl(183, 68%, 45%);
}

html {
    height: 100%;
    font-size: 16px;
}

@media (max-width: 1920px)
{
  html
  {
    font-size: 12px;
  }
}

@media (max-width: 400px)
{
  html
  {
    font-size: 10px;
  }
}

body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: Helvetica, 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Helvetica, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Helvetica, "Oswald";
}

em {
    font-size: 0.85rem;
}

input[type=checkbox] {
    transform: scale(1.25);
}

::placeholder {
    font-style: italic;
}

.btn-sx {
    color: #fff;
    background-color: var(--branding-sunburst-orange);
}

.btn-sx:hover{
    color: #fff;
    background-color: #ff854d;
}

/* Centers the content horizontally and vertically */
.centred {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text-centre {
    text-align: center;
}

.sx-heading {
    font-family: "Oswald";
    color: var(--branding-dark);
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--branding-sunburst-orange);
}

h6.sx-heading {
    margin-bottom: 5px;
}

label, .label-sx {
    font-weight: 600;
}

.modal-header {
    color: #fff;
    background-color: var(--branding-dark);
}

.modal-header button {
    color: #fff;
}

.modal-backdrop.show {
    opacity: 0.1;
}

.modal-body {
    min-height: 100px;
}

.modal-footer {
    background-color: var(--branding-feather-grey);
}

.thead-sx {
    color: #fff;
    background-color: var(--branding-dark);
}

.rdt_TableHead{
    border-bottom: 2px solid #dee2e6;
}

.rdt_TableHeadRow, .rdt_Pagination {
    background-color: var(--branding-dark) !important;
    min-height: 1rem !important;
    padding: 0.5rem 0;
}

.rdt_TableHeadRow > .rdt_TableCol .rdt_TableCol_Sortable, .rdt_Pagination{
    color: #fff !important;
    font-size: 1.1rem !important;
}

.rdt_TableCell, .rdt_Pagination{
    font-size: 1rem !important;
    font-weight: unset !important;
}

.rdt_Pagination button{
    padding: 0 0 3px 0;
    height: 27px;
}

.rdt_Pagination{
    justify-content: flex-start !important;
}

.rdt_Pagination svg{
    fill: #fff;
}

.hand {
    cursor: pointer;
}

.pac-container {
    z-index: 20000 !important;
}

.rbc-btn-group {
    display: none;
}

.rbc-event {
    background-color: var(--branding-sunburst-orange);
}

#payway-credit-card > iframe {
    height: 266px;
}