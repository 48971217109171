.signUpForm {
    display: grid;
    grid-template-rows: repeat(6, auto);
    grid-template-columns: auto 1fr;
    grid-template-areas:
        "heading heading"
        "lblFirstName txtFirstName"
        "lblLastName txtLastName"
        "lblDisplayName txtDisplayName"
        "lblEmail txtEmail"
        "btnRegister btnRegister";

    .heading {
        grid-area: heading;
    }

    .lblFirstName {
        grid-area: lblFirstName;
    }

    .txtFirstName {
        grid-area: txtFirstName;
    }

    .lblLastName {
        grid-area: lblLastName;
    }

    .txtLastName {
        grid-area: txtLastName;
    }

    .lblDisplayName {
        grid-area: lblDisplayName;
    }

    .txtDisplayName {
        grid-area: txtDisplayName;
    }

    .lblEmail {
        grid-area: lblEmail;
    }

    .txtEmail {
        grid-area: txtEmail;
    }

    .btnRegister {
        grid-area: btnRegister;
        justify-self: center;
        text-align: center;
    }

    label {
        color: #fff;
        margin-right: 10px;
        padding-top: 5px;
        justify-self: flex-start;
    }

    p {
        color: #fff;
    }
}

@media screen and (max-width: 300px) {
    .signUpForm {
        grid-template-rows: repeat(9, auto);
        grid-template-columns: auto;
        grid-template-areas: "lblFirstName""txtFirstName""lblLastName""txtLastName""lblDisplayName""txtDisplayName""lblEmail""txtEmail""btnRegister";

        label {
            margin-right: 0;
            padding-top: 0;
        }
    }
}