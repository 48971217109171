.billing-datatable
{
   header {
      padding: 0;
   }

   .total-balance-pay
   {
       color: #fff;
       background-color: var(--branding-dark);
       font-size: 1rem;
   }
}
