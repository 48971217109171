.page {
    background-image: url(/Images/landing-page-bg-image-75.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel {
    background-color: var(--branding-sunburst-orange-login);
    min-height: 600px;
    max-width: 500px;
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    grid-template-areas: "contentHeader" "contentDetail" "terms" "contentFooter";
    z-index: 10;

    .contentHeader {
        margin: 0 20px;
        grid-area: contentHeader;
    }

    .contentDetail {
        margin: 0 20px;
        grid-area: contentDetail;
        align-self: center;
        justify-self: center;
    }

    .terms {
        grid-area: terms;
        border: 1px solid #fff;
        border-radius: 5px;
        margin: 0 20px 20px 20px;
    }

    .contentLogo {
        grid-area: contentFooter;
        align-self: flex-end;
        justify-self: center;
        background-color: transparent;
        z-index: 20;
    }

    .contentFooter {
        height: 125px;
        grid-area: contentFooter;
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-template-rows: 24% 76%;
        grid-template-areas: "footerTopLeft footerRight" "footerBaseLeft footerRight";

        .ftl {
            grid-area: footerTopLeft;
            background-color: transparent;
            height: 100%;
        }

        .fbl {
            grid-area: footerBaseLeft;
            background-color: var(--branding-dark-slate-grey);
            height: 100%;
        }

        .footerRight {
            grid-area: footerRight;
            position: relative;
            height: 100%;
        }

        .footerRightClip {
            position: absolute;
            top: 0;
            left: 0;
            background-color: var(--branding-dark-slate-grey);
            height: 100%;
            width: 100%;
            clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%, 0 24%);
        }
    }

    h2,
    h5,
    p {
        margin: 20px;
        color: #fff;
        text-align: center;
    }

    button {
        width: 200px;
    }

    a{
        color: #000;
    }
}

@media screen and (max-width: 480px) {
    .panel {
        min-height: 600px;
        max-width: 350px;
    }

    .contentFooter {
        height: 90px !important;
    }

    img {
        max-height: 75px;
    }
}

@media screen and (max-width: 300px) {
    .panel {
        min-height: 600px;
        max-width: 250px;
    }

    .contentFooter {
        height: 90px !important;
    }

    img {
        max-height: 75px;
    }
}