  nav.responsive-navigation {
    background: var(--branding-light);
    left: 0;

    .menu-item {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas: "menuIcon menuName";
        height: auto;
        padding: 10px 10px 10px 18px;
        color: var(--branding-dark);
    
        &:hover {
            color: #fff;
            background-color: var(--branding-hover);
        }

        p.hide-text {
            display: none;
        }
          
        .menu-icon {
            grid-area: menuIcon;
            padding: 0;
            margin: 0;
        }

        .menu-name {
            grid-area: menuName;
            color: inherit;
            padding: 0;
            margin: 0 0 0 10px;
            align-self: center;
            justify-self: flex-start;
        }

        .menu-disabled {
            grid-area: menuName;
            color: #ccc;
            padding: 0;
            margin: 0 0 0 10px;
            align-self: center;
            justify-self: flex-start;
        }
    }

    a {
        color: var(--branding-dark);
        text-decoration: none;

    }

    .selected-menu {
        background-color: #ddd;
        text-decoration: none;
    }
  }