.contact-us {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto 400px;
    grid-template-areas: "form links""form map";
    gap: 10px;
    margin-bottom: 20px;

    @media screen and (max-width: 1199px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, max-content) 300px;
        grid-template-areas: "form""links""map";
    }

    .contact-form {
        grid-area: form;
        display: grid;
        grid-template-rows: min-content auto;
        grid-template-areas: "header""content";
        padding: 10px;
        border: 1px solid var(--branding-dim-grey);
        border-radius: 5px;

        .header {
            grid-area: header;
        }

        .content {
            grid-area: content;
            display: grid;
            grid-template-columns: max-content 1fr;
            grid-auto-rows: min-content;
            gap: 10px;
        }

        .row.options-row {
            padding: 5px;
            margin: 0 1px 10px 1px;
            border: 1px solid #ced4da;
            border-radius: 5px;

            p {
                margin-bottom: 5px;
                font-weight: 600;
            }

            .form-options {
                display: grid;
                grid-gap: 10px;

                @media screen and (min-width:1200px) {
                    grid-template-columns: 2fr 1fr;
                    grid-template-areas: "col1 col2";
                }

                @media screen and (max-width:1199px) {
                    grid-template-rows: repeat(2, auto);
                    grid-template-areas: "col1""col2";
                }
            }

            .col1 {
                grid-area: col1;
            }

            .col2 {
                grid-area: col2;
                margin-top: 0;

                @media screen and (max-width: 1199px) {
                    margin-top: -10px;
                }
            }
        }

        .logo {
            width: 100%;
            height: 100px;
            object-fit: contain;
        }
    }

    .contact-links {
        grid-area: links;
        display: grid;
        grid-template-rows: repeat(2, max-content);
        grid-template-areas: "services""certifications";
        grid-row-gap: 10px;
        background-color: var(--branding-dark);
        border-radius: 5px;
        padding-top: 10px;

        .contact-services {
            grid-area: services;
            display: grid;
            grid-template-columns: 25% repeat(3, 1fr);
            grid-template-rows: max-content auto max-content;
            grid-row-gap: 10px;
            grid-template-areas: "header header header header""service1 service2 service3 service4"". separator separator .";

            .header {
                grid-area: header;
                color: #fff;
                margin: 0 10px;
                border-bottom: 2px solid var(--branding-sunburst-orange);
            }

            .service1 {
                grid-area: service1;
            }

            .separator {
                grid-area: separator;
                border-bottom: 2px solid var(--branding-sunburst-orange);
            }

            .service2 {
                grid-area: service2;
            }

            .service3 {
                grid-area: service3;
            }

            .service4 {
                grid-area: service4;
            }

            img {
                width: 75%;
                height: auto;
            }

            a {
                text-align: center;
            }
        }

        .contact-certifications {
            grid-area: certifications;
            display: grid;
            grid-template-columns: 25% repeat(4, 1fr);
            grid-template-areas: "cert5 cert1 cert2 cert3 cert4";
            grid-gap: 10px;

            .cert1 {
                grid-area: cert1;
            }

            .cert2 {
                grid-area: cert2;
            }

            .cert3 {
                grid-area: cert3;
            }

            .cert4 {
                grid-area: cert4;
            }

            .cert5 {
                grid-area: cert5;
                justify-self: center;
            }

            img {
                width: 75%;
                height: auto;
                justify-self: flex-start;
                align-self: center;
            }
        }
    }

    .contact-map {
        grid-area: map;
        position: static !important;
        overflow: hidden;
        // width: 600px;
        // min-width: 600px;
        // height: 600px;
        // min-height: 600px;
    }

    .branch {
        margin: -6px;

        .branch-item {
            border: 2px solid var(--branding-dark);
            background-color: var(--branding-light);
            border-radius: 5px;
            white-space: nowrap;
            padding: 1rem;
            margin: 6px;
            flex-basis: 0;
            cursor: pointer;
            font-size: 1.2rem;

            &.selected {
                background-color: var(--branding-hover);
                color: white;
            }
        }
    }

    address {
        font-size: 0.75rem;
        margin-top: 4px;
        margin-bottom: 0;
    }

    .map>div {
        position: static !important;
    }
}