.service-page {
    .header {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: "header-title header-options";
    }

    .header-title {
        grid-area: header-title;
    }

    .header-options {
        grid-area: header-options;
        display: flex;
    }

    li{
        font-size: 0.875rem;
    }
}