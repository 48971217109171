.filter-clear {
   align-self: center;
   color: red;
   cursor: pointer;
}

.filter-option {
   grid-area: filterOption;
}

.filter-detail {
   grid-area: filterDetail;
   display: flex;
   gap: 10Px;
}

.flex-item {
   flex: 1 1 !important;
   flex-wrap: nowrap !important;
}

h6 {
   font-weight: 600;
}