.page-loading {
    height: 100%;
    width: 100%;
    display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-template-rows: 1fr auto 1fr;
	grid-template-areas: ". . ." ". content ." ". . .";

    .content {
        grid-area: content;
    }
}
