
.alert {
   background-color: var(--branding-dim-grey);
   color: white;
 }

.card-stretch {
   height: calc(100% - 10px);
 }

.icon {
   height: 40px;
   width: 40px;
 }
 
 .card-title {
   margin-left: 15px;
 }

 .card-body {
   :last-child {
       margin-bottom: 0;
   }
 }
 .card-body-content {
    margin-left: 55px;
 }

 .advertisement {
    font-size: .8rem;
 }

 .table-row :nth-child(even) {
    background-color: var(--branding-feather-grey);
 }

 svg[data-icon="eye"] {
    color: var(--branding-sunburst-orange);
 }