.service-calendar{
    display: grid;
    grid-template-rows: repeat(5, auto);
    grid-template-areas: "title" "sub-heading" "options" "content" "close";
    row-gap: 10px;

    .title{
        grid-area: title;
        display: flex;
        justify-content: center;
    }

    .sub-heading {
        grid-area: sub-heading;
        display: flex;
        justify-content: center;
    }

    .options{
        grid-area: options;
        display: flex;
        justify-content: center;
    }

    .content {
        grid-area: content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .close {
        grid-area: close;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
