.account-number, .billing-cycle {
   background-color: var(--branding-sunburst-orange);
   border: 1px solid var(--branding-sunburst-orange);
   border-radius: 0.25rem;
   padding: 5px;

   label {
       color: #fff;
   }
}

.account-balance {
    background-color: green;
    border: 1px solid green;
    border-radius: 0.25rem;
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: "balance-label balance-label""balance pay-button";

    label {
        grid-area: balance-label;
        color: #fff;
    }

    input {
        grid-area: balance;
    }

    button {
        grid-area: pay-button;
        margin: 0 10px;
    }

    &.account-balance-red {
        background-color: rgb(255, 0, 0);
        border: 1px solid rgb(255, 0, 0);
    }
 
    &.account-balance-orange {
        background-color: var(--branding-sunburst-orange);
        border: 1px solid var(--branding-sunburst-orange);
    }
 
    &.account-balance-yellow {
        background-color: gold;
        border: 1px solid #ccc;
 
        label {
            color: #000;
        }
    }
 }

 .flex-break {
     flex-basis: 100%;
     height: 0;
 }
