.page-layout {
    .page-banner {
        background-color: var(--branding-dark);

        .banner-fill {
            background-image: url(/Images/banner-fill.jpg);
            background-position: center;
            background-size: auto;
            background-repeat: repeat-x;
            padding: 0;
        }

        @media (max-width: 768px) {
            h4 {
                max-width: 380px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        @media (max-width: 576px) {
            .banner-fill {
                background-image: unset;
            }

            .logo {
                height: 90px;
            }
        }

        h4 {
            color: #fff;
            text-transform: uppercase;
        }
    }

    .page-navigation {
        min-height: calc(100vh - 100px);
        height: 100%;
        width: 220px;
        background-color: #f8f9fa;
        margin: 0;

        &.collapsed {
            width: 80px;
        }

        @media (max-width: 480px) {
            width: 180px;

            &.collapsed {
                width: 75px;
            }
        }
    }

    .nav-tabs .nav-link {
        cursor: pointer;

        &.active {
            color: var(--branding-sunburst-orange);
        }
    }

}

/* -- Create Extra Small button -- */
.btn-group-xs>.btn,
.btn-xs {
    padding: .3rem .3rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}

/* -- This is required to ensure any alerts "slide under" the input field -- */
input {
    position: relative;
    //z-index: 999;
}

.input-group-append {
    position: relative;
    z-index: 999;
}

.input-group-prepend {
    position: relative;
    z-index: 999;
}

.alert-error {
    padding: 0.25rem 0.75rem;
    margin-top: -5px;
    margin-bottom: 0.25rem;
    width: 100%;
    z-index: 500;
}

/* ------------------------------------------------------------------------- */

.alert-sx {
    color: white;
    background-color: var(--branding-sunburst-orange);
}

.card {
    margin-bottom: 10px;
    border: 2.5px solid var(--branding-feather-grey);
    border-radius: 1rem;

    .card-header {
        color: var(--branding-sunburst-orange);
        font-weight: 600;
        background-color: transparent;
        border-bottom-width: 0;
        font-size: 1.15rem;
    }

    .card-body {
        padding-top: 0;
    }
}

.mandatory::before {
    color: #e00f02;
    content: "*";
    font-size: 1.25rem;
    display: inline;
}

.one-of::before {
    color: blue;
    content: "*";
    font-size: 1.25rem;
    display: inline;
}

.Toastify__toast-container {
    min-width: 500px;
    font-size: 0.85rem;
}

.Toastify__toast {
    border: 2px solid var(--branding-dark-slate-grey) !important;
    border-radius: 5px !important;
}

.Toastify__toast--error {
    background-color: #fff !important;
    border: 1px solid var(--branding-error) !important;

    h4 {
        color: var(--branding-error) !important;
    }
}

.Toastify__progress-bar--error {
    background: var(--branding-error) !important;
}

.Toastify__close-button--error {
    color: var(--branding-error) !important;
}

.Toastify__toast--info {
    background-color: #fff !important;
    border: 1px solid darkBlue !important;

    h4 {
        color: darkBlue !important;
    }
}

.Toastify__progress-bar--info {
    background: darkBlue !important;
}

.Toastify__close-button--info {
    background: darkBlue !important;
}

.Toastify__toast--success {
    background-color: #fff !important;
    border: 1px solid var(--branding-sea-green) !important;

    h4 {
        color: var(--branding-sea-green) !important;
    }
}

.Toastify__progress-bar--success {
    background: var(--branding-sea-green) !important;
}

.Toastify__close-button--success {
    background: var(--branding-sea-green) !important;
}

.Toastify__toast--warning {
    background-color: #fff !important;
    border: 1px solid var(--branding-sunburst-orange) !important;

    h4 {
        color: var(--branding-sunburst-orange) !important;
    }
}

.Toastify__progress-bar--warning {
    background: var(--branding-sunburst-orange) !important;
}

.Toastify__close-button--warning {
    background: var(--branding-sunburst-orange) !important;
}

.Toastify__toast-body {
    text-align: center;
    font-size: 0.85rem;
    color: #000;

    h4 {
        font-size: 1.5rem;
    }
}

.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f8f8f8ad;

    .fp-loader {
        position: absolute;
        top: 30%;
        left: 48%;
        z-index: 1100;
    }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    flex: 1 1;
}

.react-datepicker-popper {
    z-index: 100000 !important;
}

.dropdown-item>a {
    color: black;
    text-decoration: none;
}

.dropdown-item:hover,
.dropdown-item:hover>a,
.dropdown-item:active,
.dropdown-item:active>a {
    color: white !important;
    background-color: var(--branding-sunburst-orange);
}

.rdt_TableCol,
.rdt_TableCol_Sortable {
    font-size: 0.85rem !important;
}